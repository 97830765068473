<template>
  <div>
    <div class="flex">
      <div class="hidden sm:flex items-center w-18 flex-shrink-0">
        <button
          type="button"
          class="w-12 h-12 border border-guestio-gray-50 rounded-full flex items-center justify-center shadow-xl transition duration-150 ease-in-out"
          :class="{ 'bg-pink-500 border-pink-500': selected.length }"
          @click.prevent="toggleSelection"
        >
          <CheckIcon v-if="selected.length" class="h-8 text-white" />
        </button>
      </div>
      <div @click="toggleSelection" class="cursor-pointer bg-white flex-1 border border-guestio-gray-50 shadow-xl flex rounded-xl px-4 py-6 transition duration-150 ease-in-out" :class="{ 'border-pink-500': selected.length }">
        <div class="flex-shrink-0 flex justify-end px-3">
          <img :src="option.icon_url" class="h-8 w-8" alt="">
        </div>
        <div class="flex-1">
          <h3 class="font-bold text-xl text-blue-800">Virtual Event</h3>
          <p class="text-sm text-gray-500 mt-2">
            <!-- Shows can book you for virtual stages like board meetings, virtual conferences or private dinners. -->
            {{ option.description }}
          </p>
        </div>
      </div>
    </div>

    <div v-if="selected.length" class="pl-0 sm:pl-20 mt-6">
      <div class="flex items-center">
        <div>
          <ValidationProvider name="Price" rules="required|min_value:5" v-slot="{ errors }">
            <div
              class="rounded-full bg-white py-3 px-5 flex items-center w-32 border border-guestio-gray-50"
              :class="[{'border border-red-500': errors.length}]"
            >
              <DollarSignIcon class="mr-3 flex-shrink-0"/>
              <input
                id="price"
                type="number"
                min="5"
                oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
                class="bg-transparent w-full"
                v-model="selected[0].price"
              >
            </div>
            <p class="form-error text-red-500 text-sm mt-1 font-light" v-if="errors.length">{{ errors[0] }}</p>
          </ValidationProvider>
        </div>

        <div class="ml-4">
          per event
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import CheckIcon from '@/components/svgs/CheckIcon'
  import DollarSignIcon from '@/components/svgs/DollarSignIcon'

  export default {
    name: 'VirtualEventOption',

    props: {
      option: Object,

      prices: {
        type: Array,
        default: () => ([])
      },
    },

    components: {
      CheckIcon,
      DollarSignIcon,
    },

    data() {
      return {
        selected: [],

        defaultItem: {
          booking_option_id: this.option.id,
          price: 500,
          interview_duration: 0,
        }
      }
    },

    watch: {
      selected: {
        deep: true,
        handler(selected) {
          this.$emit('updated', selected)
        }
      }
    },

    methods: {
      toggleSelection() {
        if (this.selected.length) {
          this.selected = []
          return
        }

        this.selected.push({
          ...this.defaultItem
        })
      },
    },

    mounted() {
      if (this.prices && this.prices.length) {
        this.selected = [...this.prices]
      }
    }
  }
</script>
